.blobOneWrapper {
  width: 700px;
  position: absolute;
  margin-top: 40vh;
  left: -10vw;

  @media (max-width: 1024px) {
    left: -40vw;
  }

  @media (max-width: 767px) {
    left: -30vw;
  }
}

.blobTwoWrapper {
  width: 700px;
  position: absolute;
  top: -20vh;
  right: -10vw;
}
