.section {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: whitesmoke;
  bottom: -100vh;
  display: none;
}

.wrapperWhatWeDo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  position: relative;

  .text-container {
    position: relative;
    display: flex;
    bottom: 80px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1440px) {
      transform: scale(1);
    }

    @media (max-width: 1024px) {
      // bottom: 70px;
      flex-direction: column-reverse;
      transform: scale(0.8);
    }

    @media (max-width: 768px) {
      // bottom: 80px;
      transform: scale(0.9);
    }

    @media (max-width: 375px) {
      // bottom: 20px;
      transform: scale(1);
      bottom: 0;
    }
  }

  .who-we-are-container {
    display: flex;
    flex-direction: column;
    font-family: "Space Mono", monospace;
    font-family: Karla;
    font-weight: 100;
    align-items: center;
    justify-content: center;
    color: black;
    position: relative;
    margin: 1.5rem 5rem;
    max-width: 1024px;

    // @media (min-width: 1025px) {
    //   &:first-of-type {
    //     margin-bottom: -150px;
    //   }
    //   &:last-of-type {
    //     margin-bottom: 150px;
    //   }
    // }

    @media (max-width: 475px) {
      margin: 1.5rem 2rem;
    }

    .who-we-are-text-header {
      font-size: 50px;
      font-family: Khula;
      font-weight: 800;
      position: relative;
      // margin: 40px 0;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 20px;
        background-color: white;
        top: 45px;
        left: 0;
        z-index: -1;
      }

      @media (min-width: 1440px) {
        // margin: 0;
      }

      @media (max-width: 1024px) {
        // margin: 10px;
      }

      @media (max-width: 768px) {
        // margin: 30px;
      }

      @media (max-width: 375px) {
        // margin: 10px;
      }
    }

    .who-we-are-text {
      font-size: 1.3em;
      text-align: center;
      // width: 30vw;
      // line-height: 40px;
      font-family: Khula;
      font-weight: 300;

      a {
        color: #000;
        text-decoration: underline;
        transition: color 0.5s ease-in-out;

        &:hover {
          color: #fff;
        }
      }

      @media (max-width: 1024px) {
        font-size: 1.3em;
        line-height: 40px;
        // width: 80vw;
      }

      @media (max-width: 767px) {
        font-size: 1.1em;
        line-height: 30px;
        // width: 74vw;
      }

      @media (max-width: 375px) {
        font-size: 0.7em;
        line-height: 25px;
        // width: 80vw;
      }
    }
  }

  .hero-marquee {
    overflow: hidden;
    white-space: nowrap;
    transform: skewY(-3deg);
    position: relative;

    @media (max-width: 375px) {
      display: none;
    }
  }

  .marquee {
    font-size: 0;
    @media (max-width: 375px) {
      display: none;
    }
  }

  .clipped-text {
    display: inline-block;
    font-size: 290px;
    line-height: 190px;
    margin: 30px 0;
    text-shadow: 11px 10px rgba(1, 1, 1, 0.3);
  }

  .robotics {
    color: black;
    background-clip: text;
    font-family: Kanit;
    text-transform: uppercase;
    transform-origin: center center;
  }
}
