.sectionContact {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgb(238, 238, 222);
  top: 0;
  left: -100vw;
  display: none;
  overflow: hidden;

  // .button {
  //   // display: block;
  //   border: 2px solid #000;
  //   width: min-content;
  //   background-color: rgb(238, 238, 222);
  //   box-shadow: 5px 5px #000;
  //   text-align: center;
  //   padding: 10px;
  // }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
    width: 100vw;
    flex-direction: row;

    a {
      color: #000;
      text-decoration: none !important;
      transition: color 0.5s ease-in-out;
      font-weight: 800;
      position: relative;

      &:after {
        position: absolute;
        width: 100%;
        content: "";
        height: 2px;
        background-color: black;
        bottom: 0;
        left: 0;
        transform: scale(0);
        transition: transform 0.5s ease-in-out;
      }

      &:hover {
        &:after {
          transform: scale(1);
        }
      }
    }

    .contact-wrapper {
      position: absolute;
      background-color: rgba($color: #edeedc, $alpha: 1);
      display: flex;
      flex-direction: column;
      padding: 50px;
      margin-bottom: 50px;
      z-index: 4;
      opacity: 1;
      justify-content: center;
      align-items: center;
      width: 35%;
      text-align: center;

      @media (max-width: 1024px) {
        width: 65%;
        padding: 20px;
        margin: 0;
      }

      @media (max-width: 767px) {
        width: 65%;
        padding: 20px;
        margin: 0;
      }

      @media (max-width: 375px) {
        width: 100%;
        padding: 0;
        margin: 0;
      }
    }

    .contact-text-header {
      font-size: 40px;
      font-family: Khula;
      position: relative;
      font-weight: 800;
      z-index: 1;
      color: #042121e6;

      @media (max-width: 1024px) {
        font-size: 25px;
      }

      @media (max-width: 767px) {
        font-size: 25px;
      }

      @media (max-width: 375px) {
        padding: 10px;
        font-size: 30px;
      }
    }

    .contact-text {
      position: relative;
      color: rgba($color: #042121e6, $alpha: 0.9);
      font-size: 23px;
      font-family: Khula;
      text-align: center;
      width: 30vw;
      font-weight: 100;

      @media (max-width: 1024px) {
        font-size: 15px;
      }

      @media (max-width: 767px) {
        font-size: 17px;
        width: 40vw;
      }

      @media (max-width: 375px) {
        width: 100%;
        padding: 10px;
        font-size: 17px;
      }
    }

    .services {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 100vw;
      height: 100vh;
      word-break: break-word;
      text-align: justify;
      position: relative;

      .inner-wrapper {
        position: absolute;
        height: 80vh;
        width: 85vw;
        overflow: hidden;
        text-align: center;

        @media (max-width: 1024px) {
          width: 80vw;
          height: 60vh;
        }

        @media (max-width: 768px) {
          // font-size: 2em;
          height: 70vh;
        }

        @media (max-width: 375px) {
          width: 80vw;
          height: 100vh;
          top: 20px;
        }
      }

      .service-text {
        text-shadow: 8px 8px rgba(1, 1, 1, 0.12);
        padding-right: 20px;
        // text-align: justify;
        color: transparent;
        background-clip: text;
        font-size: 6rem;
        // text-align: justify;
        text-transform: uppercase;
        -webkit-text-stroke: 1px rgba($color: black, $alpha: 1);
        font-family: "Kanit", sans-serif;
        z-index: 3;
        position: relative;
        text-align: center;
        line-height: 88px;

        @media (max-width: 1024px) {
          font-size: 3em;
          line-height: 35px;
        }

        @media (max-width: 767px) {
          font-size: 3em;
          line-height: 40px;
        }

        @media (max-width: 375px) {
          font-size: 1rem;
          line-height: 35px;
          display: none;
        }
      }
    }
  }
}
