.nav {
  user-select: none;
  color: white;
  position: absolute;
  width: 100vw;
  height: 100vh;
  font-family: "Khula", sans-serif;
  font-weight: 800;
  text-transform: uppercase;

  @media (max-width: 768px) {
    display: none;
  }

  .about {
    position: absolute;
    transform: rotate(-90deg) translate(0, 50%);
    transform-origin: left;
    margin: 0 20px;
    top: 50%;
    z-index: 3;
    mix-blend-mode: difference;
    font-size: 30px;
    transition: font-size 0.5s ease;

    @media (max-width: 768px) {
      font-size: 25px;
    }

    @media (max-width: 375px) {
      font-size: 20px;
    }

    &::after {
      position: absolute;
      width: 100%;
      transform: scale(0);
      content: "";
      height: 2px;
      background-color: white;
      left: 0%;
      top: 50px;
      transition: transform 0.5s ease-in-out;
    }

    &:hover {
      font-size: 32px;
      &::after {
        transform: scale(1);
      }
    }
  }

  .work {
    position: absolute;
    top: 0;
    z-index: 3;
    mix-blend-mode: difference;
    left: 50%;
    margin: 20px 0;
    transform: translate(-50%, 0%);
    font-size: 30px;
    transition: font-size 0.5s ease;

    @media (max-width: 768px) {
      font-size: 25px;
    }

    @media (max-width: 375px) {
      font-size: 20px;
    }

    &::after {
      position: absolute;
      width: 100%;
      transform: scale(0);
      content: "";
      height: 2px;
      background-color: white;
      left: 0%;
      top: 50px;
      transition: transform 0.5s ease-in-out;
    }

    &:hover {
      font-size: 32px;
      &::after {
        transform: scale(1);
      }
    }
  }

  .contact {
    mix-blend-mode: difference;
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 3;
    font-size: 30px;
    transform: translate(-50%, 0%);
    margin: 20px 0;
    transition: font-size 0.5s ease;

    @media (max-width: 768px) {
      font-size: 25px;
    }

    @media (max-width: 375px) {
      font-size: 20px;
    }

    &::after {
      position: absolute;
      width: 100%;
      transform: scale(0);
      content: "";
      height: 2px;
      background-color: white;
      left: 0%;
      top: 50px;
      transition: transform 0.5s ease-in-out;
    }

    &:hover {
      font-size: 32px;
      &::after {
        transform: scale(1);
      }
    }
  }
}
.main {
  height: 100vh;
  background-color: black;
  width: 100vw;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    height: 150px;
    width: 100vw;
    z-index: -1;
    transform: skewY(-3deg);
    transform-origin: top left;
    bottom: 150px;
  }

  .row {
    height: 100vh;
    align-items: center;
    justify-content: center;
    width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;

    .ltr {
      position: relative;
      top: 0;
    }

    h1 {
      display: flex;
      flex-direction: column;
      text-align: center;
      color: white;
      mix-blend-mode: difference;
      overflow: hidden;
      font-family: "Khula", sans-serif;
      font-weight: 800;
      margin: 0 10px;
      text-transform: uppercase;

      .one {
        font-size: 6rem;
        height: 145px;
        margin: 0px;

        @media (max-width: 1024px) {
          font-size: 1.7em;
          height: 90px;
        }

        @media (max-width: 768px) {
          font-size: 0.9em;
          height: 70px;
        }

        @media (max-width: 375px) {
          font-size: 0.8em;
          height: 90px;
        }
      }
      .two {
        font-size: 2rem;
        height: 55px;

        @media (max-width: 768px) {
          font-size: 0.7em;
          height: 50px;
        }

        @media (max-width: 375px) {
          font-size: 0.5em;
          height: 40px;
        }
      }
    }

    .btn-row {
      width: 256px;
      position: relative;
      z-index: 2;
      a {
        font-size: 1.6rem;
        color: black;
        display: flex;
        text-decoration: none;
        align-items: center;
      }
    }
  }
}

.mobile-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-transform: uppercase;
}

.mobile-menu-overlay {
  position: absolute;
  background: #000;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 3rem;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;

  @media (min-width: 768px) {
    display: none;
  }
}

.mobile-nav {
  position: absolute;
  z-index: 5;

  @media (min-width: 768px) {
    display: none;
  }
}
