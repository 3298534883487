@import "./config";
@import "./components/Banner/banner";

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: white;
  overflow: hidden;
}
.container {
  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
  }

  .v-center {
    align-items: center;
  }
  .space-between {
    justify-content: space-between;
  }
}

.header {
  position: fixed;
  width: 100%;
  height: 128px;
  z-index: 4;
}

.logo {
  a {
    text-decoration: none;
    letter-spacing: 0.05em;
    font-size: 1.5rem;
    color: black;
    font-weight: 700;
    mix-blend-mode: difference;
    font-family: "Lilita One", cursive;
  }
}

.menuButton {
  width: 25px;

  span {
    margin-bottom: 0.3em;
    height: 2px;
    width: 100%;
    display: block;
  }
}

.intro-overlay {
  .top {
    width: 100vw;
    position: absolute;
    height: 100%;

    .overlay-top {
      position: absolute;
      height: 100vh;
      width: 33.33vw;
      top: 0;
      bottom: 0;
      left: 0;

      &:nth-child(2) {
        left: 33.333%;
      }
      &:nth-child(3) {
        left: 66.666%;
      }
    }
  }
}
