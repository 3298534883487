.sectionWork {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: gold;
  top: 0;
  left: -100vw;
  display: none;
  justify-content: center;
  align-items: center;
  display: none;
  overflow: hidden;
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  flex-wrap: wrap;
  overflow: hidden;

  .rowWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;

    .gridWrapper {
      display: flex;
      @media (max-width: 767px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        // margin: 30px 0;
      }
    }
  }

  .colWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .text-wrapper {
      margin: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 767px) {
        margin: 0;
      }
    }
  }

  .text-header {
    font-family: Khula;
    font-weight: 800;
    width: 100%;
    position: relative;
    z-index: 1;
    color: transparent;
    text-transform: uppercase;
    -webkit-text-stroke: 2px black;
    text-shadow: 10px 10px rgba($color: rebeccapurple, $alpha: 1);
    text-align: center;

    @media (min-width: 320px) {
      font-size: 2em;
      margin-top: 40px;
    }

    @media (min-width: 375px) {
      font-size: 3em;
      margin-top: 40px;
    }

    @media (min-width: 768px) {
      font-size: 6em;
    }

    @media (min-width: 1024px) {
      font-size: 7em;
    }
    @media (min-width: 1440px) {
      font-size: 10em;
    }
  }
  .text {
    font-size: 20px;
    font-family: Khula;
    font-weight: 300;
    width: 30vw;
    height: 100%;
    position: relative;
    z-index: 1;
    margin: 0 50px;
    text-align: center;

    @media (min-width: 320px) {
      width: 90vw;
      // margin: 20px 0px;
    }

    @media (min-width: 375px) {
      width: 90vw;
      // margin: 40px 10px;
    }

    @media (min-width: 768px) {
      width: 50vw;
    }

    @media (min-width: 1024px) {
      width: 30vw;
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 0;
    flex-wrap: wrap;
    z-index: 2;
    border-radius: 30px;

    @media (min-width: 1024px) {
      margin: 20px 50px;
    }

    @media (max-width: 767px) {
      margin: 20px 10px;
      mix-blend-mode: difference;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 30px;
      left: -10px;
      top: -10px;
      z-index: 1;
    }

    .headerItem {
      font-weight: 800 !important;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        height: 1px;
        width: 50px;
      }
    }

    .item {
      font-size: 15px;
      font-family: Khula;
      font-weight: 300;
      position: relative;
      color: rebeccapurple;
      padding: 5px 10px;
      text-transform: uppercase;
      letter-spacing: 2px;

      &:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 1px;
        top: 0;
        left: -5px;
      }
    }
  }
}
